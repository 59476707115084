import { useState, useEffect } from 'react'

export const useScroll = () => {
  const isClient = typeof document !== `undefined`

  const [scroll, setScroll] = useState({
    x: isClient ? document.body.getBoundingClientRect().left : 0,
    y: isClient ? document.body.getBoundingClientRect().top : 0,
    direction: ''
  })

  const listener = e => {
    setScroll(prev => ({
      x: isClient ? document.body.getBoundingClientRect().left : 0,
      y: isClient ? -document.body.getBoundingClientRect().top : 0,
      direction: isClient ? (prev.y > -document.body.getBoundingClientRect().top ? 'up' : 'down') : ''
    }))
  }

  useEffect(() => {
    if (isClient) {
      window.addEventListener('scroll', listener)
      return () => window.removeEventListener('scroll', listener)
    }
  }, [isClient])

  return scroll
}