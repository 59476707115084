import { css } from "@emotion/core"

export const scrollWrapper = css`
  grid-column: 1 / -1;
  grid-row: 2;
  text-align: center;
`;

export const titleWrapper = (theme) => css`
    position: relative;
    grid-column: 1 / -1;

    h1 {
        font-size: 320px;
        text-align: center;
        white-space: nowrap;
        display: inline-block;

        @media (max-width: ${ theme.breakpoint.mobile }) {
            font-size: 120px;
        }
    }
`;

export const categoryTag = css`
    text-align: center;
    padding: 3.5em 0 0;
    color: #86868B;
    width: 90vw;
    margin: 0 auto;
`;

export const stickyTitleWrapper = css`
  position: sticky;
  top: 0em;
`;

export const main = (theme) => css`
    grid-row: 2;
    grid-column: 1 / -1;

    display: grid;
    grid-template-columns: repeat(12,1fr);
    grid-gap: 2em;
    grid-template-rows: 2;
    grid-row-gap: 4em;

    @media (max-width: ${ theme.breakpoint.mobile }) {
        grid-gap: initial;
        grid-row-gap: 2em;
    }

    width: 90vw;

    .wp-block-image {
        grid-column: 1 / -1;
    }

    .has-text-align-center, .has-text-align-left {
        font-size: 1.375em;
        line-height: 1.4;
        grid-column: 3 / span 8;
        text-align: center;

        a {
            text-decoration: underline;
        }

        @media (max-width: ${ theme.breakpoint.mobile }) {
            grid-column: span 12;
        }
    }

    .wp-block-group {
        grid-column: 5 / span 4;

        &.alignwide {
            grid-column: 1 / -1;
        }
    }

    .wp-block-columns {
        grid-column: 1 / span 12;
        display: flex;
        justify-content: space-between;
    }

    .wp-block-column {
        p:first-of-type {
            margin-bottom: 0.5em;
        }
    }

    .wp-block-gallery {
        grid-column: 1 / -1;
    }

    .blocks-gallery-grid {
        display: grid;
        grid-template-columns: repeat(12,1fr);
        grid-gap: 2em;
        grid-template-rows: 2;
        grid-row-gap: 2em;

        @media (max-width: ${ theme.breakpoint.mobile }) {
            grid-gap: initial;
            grid-row-gap: 1em;
        }
    }

    .wp-block-gallery.columns-2 .blocks-gallery-item {
        grid-column: span 6;

        @media (max-width: ${ theme.breakpoint.mobile }) {
            grid-column: span 12;
        }
    }

    .wp-block-gallery.columns-3 .blocks-gallery-item {
        grid-column: span 4;

        @media (max-width: ${ theme.breakpoint.mobile }) {
            grid-column: span 12;
        }
    }

    .wp-block-gallery.columns-4 .blocks-gallery-item {
        grid-column: span 3;

        @media (max-width: ${ theme.breakpoint.mobile }) {
            grid-column: span 12;
        }
    }

    .wp-block-gallery.columns-6 .blocks-gallery-item {
        grid-column: span 2;

        @media (max-width: ${ theme.breakpoint.tablet }) {
            grid-column: span 4;
        }

        @media (max-width: ${ theme.breakpoint.mobile }) {
            grid-column: span 12;
        }
    }

    .blocks-gallery-item figure {
        height: 100%;
    }

    .blocks-gallery-item img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .wp-block-embed-vimeo {
        grid-column: 1 / -1;

        @media (max-width: ${ theme.breakpoint.mobile }) {
            grid-column: span 12;
        }
    }

    .wp-block-embed-vimeo .wp-block-embed__wrapper {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
    }

    .wp-block-embed-vimeo .wp-block-embed__wrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    figcaption {
        margin-top: 2em;
        font-style: italic;
        text-transform: capitalize;
        
    }
`;

export const footer = css`
    grid-row: 2;
    grid-column: 4 / span 6;
    text-align: center;
    padding: 10em 0 10em;
    width: 90vw;

    &:hover .footerTags {
        transform: translateY(0);
        opacity: 1;
    }
`;

export const footerLabel = css`
    margin-bottom: 0.25em;
`;

export const footerTags = css`
    margin-top: 0.5em;
    transform: translateY(1em);
    opacity: 0;
    transition: all 500ms cubic-bezier(.215,.61,.355,1);
`;

