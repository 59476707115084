import React, { useRef, useEffect, useState } from "react"
import { css } from "@emotion/core"

import { modulate } from "../../helpers/modulate";
import { useScroll } from "../../hooks/scrollHook";

import { scrollWrapper, main, categoryTag, titleWrapper, stickyTitleWrapper, footer, footerLabel, footerTags } from "./case-details.styles";

const CaseDetails = ({ data }) => {
    const post = data.wordpressPost

    const titleWrapperRef = useRef(null);
    const titleRef = useRef(null);
    const scrollWrapperRef = useRef(null);
  
    const { y } = useScroll({})
  
    const [xTransform, setXTransform] = useState(0);
    const [yTransform, setYTransform] = useState(0);
    const [opacity, setOpacity] = useState(0);

    var height = 0;
  
    useEffect(() => {
      const labelWidth = titleRef.current.getBoundingClientRect().width - (0.9 * window.innerWidth);
      const labelHeight = titleRef.current.getBoundingClientRect().height;
  
      const scrollWrapperHeight = scrollWrapperRef.current.getBoundingClientRect().height;
      const scrollHeight = window.innerHeight - labelHeight;
      
      height = scrollWrapperHeight + scrollHeight;
  
      setXTransform(modulate(y, [0, scrollHeight], [0, (labelWidth > 0) ? labelWidth : 0], true));
      setYTransform(modulate(y, [0, scrollHeight], [120, 40], true));
      setOpacity(modulate(y, [0, scrollHeight], [0.2, 1], true));
  
      if (!titleWrapperRef.current.style.height) {
        titleWrapperRef.current.style.height = `${ height }px`;
      }
    }, [y])
  
    const nextPostItem = () => {
      var nextPost = data.allWordpressPost.edges.filter( relevantPost => relevantPost.node.id === post.id )
  
      if (!nextPost[0]) {
        nextPost = data.allWordpressPost.edges
      }

      if (!nextPost[0].next) {
        nextPost = data.allWordpressPost.edges
      }
  
      return (
        <a href={ `/${nextPost[0].next.slug}`}>
          <p className = { "intro" } css = { footerLabel }>Next Project</p>
          <h2> { nextPost[0].next.title } </h2>
          <p className = { "footerTags" } css = { footerTags }>{ nextPost[0].next.categories[0].name }</p>
        </a>
      )
    }

    return (
        <div ref = { scrollWrapperRef } css = { scrollWrapper }>
            <div ref = { titleWrapperRef } css = { titleWrapper }>
                <div css = { stickyTitleWrapper }>
                <header css = { css`width: 100vw; margin-left: -5vw; overflow-x: hidden;` }>
                    <div style = { { width: `90vw`}}>
                    </div>
                    <p css = { categoryTag }>{ post.categories[0].name }</p>
                    <h1 ref = { titleRef } css = { css`margin-left: 5vw` } style = {{ transform: `translateX(${-1 * xTransform}px)` }} className={ "caseTitle" }>{ post.title }</h1>
                </header>
                <div style = {{ transform: `translateY(${yTransform}px)`, opacity: `${ opacity }` }}>
                    <main css={ main } dangerouslySetInnerHTML={ { __html: post.content } }></main>
                    <footer css = { footer }>
                    { nextPostItem() }
                    </footer>
                </div>
                </div>
            </div>
        </div>
    )
}

export default CaseDetails