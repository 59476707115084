import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../components/layout';
import CaseDetails from '../../components/case/case-details';

const CasePage = ({ data }) => {
  return (
    <Layout canNavigateBack = { true }>
      <CaseDetails data = { data } />
    </Layout>
  )
}

export const postQuery = graphql`
  query postQuery($id: String) {
    allWordpressPost(filter: {sticky: {eq: false}}) {
      edges {
        node {
          title
          id
        }
        next {
          title
          id
          categories {
            name
          }
          slug
        }
      }
    }
    wordpressPost(id: {eq: $id}) {
      content
      title
      id
      categories {
        name
      }
    }
  }
`

export default CasePage
